<template>
    <div class="fields-wrapper">
        <div class="field">
            <label>Complaint Subject</label>
            <multiselect
                v-model="data.subject" 
                :options="subjects" 
                :show-labels="false" 
                :allow-empty="true"
                :multiple="false"
                group-values="themes" 
                group-label="category"
                :group-select="false"
                :disabled="loading"
            ></multiselect>
            <span class="error" v-if="errors.subject">{{ errors.subject }}</span>
        </div>
        <div class="field">
            <label>Your e-mail address</label>
            <input v-model="data.email" :disabled="loading" />
            <span class="error" v-if="errors.email">{{ errors.email }}</span>
            <p class="note">You will receive the link for the verification.</p>
        </div>
        <div class="field">
            <label>Select Casino</label>
            <multiselect
                v-model="data.casino" 
                :options="casinos.map(type => type.alias)" 
                :custom-label="opt => (casinos.find(x => x.alias == opt) || {}).title"
                :show-labels="false" 
                :allow-empty="true"
                :disabled="loading"
            ></multiselect>
            <span class="error" v-if="errors.casino">{{ errors.casino }}</span>
        </div>
        <div class="field">
            <label>Your username at the casino</label>
            <input v-model="data.username" :disabled="loading" />
            <span class="error" v-if="errors.username">{{ errors.username }}</span>
            <p class="note">We will only use it for solving the issue and will not publish it online.</p>
        </div>
        <div class="field">
            <label>Disputed amount</label>
            <div class="amount-wrapper">
                <div class="field short amount">
                    <input v-model="data.amount" :disabled="loading" />
                </div>
                <div class="field short">
                    <multiselect
                        v-model="data.currency" 
                        :options="currencies" 
                        :show-labels="false" 
                        :allow-empty="false"
                        :disabled="loading"
                    ></multiselect>
                </div>
            </div>
            <span class="error" v-if="errors.amount">{{ errors.amount }}</span>
        </div>
        <div class="field">
            <label>Describe your problem</label>
            <textarea v-model="data.message" :disabled="loading"></textarea>
            <span class="error" v-if="errors.message">{{ errors.message }}</span>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
	name: 'PublicFormComplaint',
    props: [
        'data',
        'meta',
        'errors',
        'message',
        'loading',
    ],
	components: {
		Multiselect,
	},
    data() {
        return {
            currencies: [],
            casinos: [],
            subjects: [],
        }
    },    
    created() {
        this.$emit('loading', true)

        fetch('/api/v3/complaints/options', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        })
        .then(stream => stream.json())
        .then(data => {
            this.currencies = data.currencies
            this.casinos = data.casinos
            this.subjects = data.subjects
        })
        .catch(error => console.error(error))            
        .finally(() => this.$emit('loading', false))

        const url = new URL(window.location)
        if (url.hash) {    
            this.$emit('update', {casino: url.hash.substr(1)})    
        }
    }
}
</script>