import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import VueAppend from 'vue-append'
import PublicFilterCasino from './components/PublicFilterCasino.vue'
import PublicFilterSlots from './components/PublicFilterSlots.vue'
import PublicSearch from './components/PublicSearch.vue'
import PublicGeo from './components/PublicGeo.vue'
import PublicForm from './components/PublicForm.vue'
import PublicSlotPlay from './components/PublicSlotPlay.vue'
import PublicComparatorButton from './components/PublicComparatorButton.vue'
import PublicComparatorPanel from './components/PublicComparatorPanel.vue'
import ComparatorStore from './components/StoreComparator'
  
Vue.use(Vuex)
Vue.use(VueCookies)
Vue.use(VueAppend)
Vue.config.productionTip = false

window.app = {
    init_search: function(el, is_return=false) {
        new Vue({render: h => h(PublicSearch, {
            props: {
                focus: true,
                ctrl: is_return ? el.innerHTML : null,
            },
        })}).$mount(el)
    },
    init_menu: function(_id, _ids) {
        Array.from(_ids).forEach((el) => document.getElementById(el).classList.remove('active'));
        document.getElementById(_id).classList.toggle('active')
        if (document.getElementById(_id).classList.contains('active')) {
            document.getElementById("body").classList.add('no-scroll')
        } else {
            document.getElementById("body").classList.remove('no-scroll')
        }
    },
    init_filter_casinos: function(id, category, tags, sorting, cpp, total, country, iso_country, grid_mode=false, paging_mode='page') {
        new Vue({render: h => h(PublicFilterCasino, {props: {
            id: id, 
            category: category,
            tags: tags,
            sorting: sorting, 
            cpp: cpp,
            total: total,
            country: country,
            iso_country: iso_country,
            grid_mode: grid_mode,
            paging_mode: paging_mode
        }})}).$mount('#c_' + id)
    },
    init_filter_slots: function(id, slot_features, sorting, cpp, total, paging_mode='page') {
        new Vue({render: h => h(PublicFilterSlots, {props: {
            id: id, 
            slot_features: slot_features,
            sorting: sorting, 
            cpp: cpp,
            total: total,
            paging_mode: paging_mode
        }})}).$mount('#s_' + id)
    },
    init_geo: function(el, country, iso, location, location_iso, label=null) {
        new Vue({render: h => h(PublicGeo, {props: {
            country: country, 
            iso: iso,
            location: location,
            location_iso: location_iso,
            label: label
        }})}).$mount(el)
    },
    init_form: function(el, meta) {
        new Vue({render: h => h(PublicForm, {props: {
            meta: meta 
        }})}).$mount(el)
    },
    init_play: function(el, alias) {
        new Vue({render: h => h(PublicSlotPlay, {props: {
            alias: alias 
        }})}).$mount(el)
    },
    init_comparator_button: function(id, alias, title, logo, _add, _exist) {
        new Vue({
            store: new Vuex.Store(ComparatorStore),
            render: h => h(PublicComparatorButton, {props: {
                alias: alias,
                title: title,
                logo: logo, 
                terms: {
                    add: _add,
                    exist: _exist
                }
            }})}).$mount('#' + id)
    },
    init_comparator_panel: function(id, cnt, url, _clear, _compare) {
        new Vue({
            store: new Vuex.Store(ComparatorStore),
            render: h => h(PublicComparatorPanel, {props: {
                url: url,
                cnt: cnt,
                terms: {
                    clear: _clear,
                    compare: _compare,
                }
            }})}).$mount('#' + id)
    },
    init_comparator_select: function(payload) {
        const store = new Vuex.Store(ComparatorStore)
        store.dispatch('comparator_load', {cnt: 3})
        store.dispatch('comparator_add', payload)
        window.location.reload()
    },
    scroll_top: function() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;        
    }
}
