<template>
    <div class="filter-wrapper" ref="wrapper">
        <div class="filter-top-action">
            <button @click="open" class="open">{{ filter_info }}</button>
            <button @click="clear" class="clear" v-if="filters.length > 0 || query">Clear All Filters</button>
        </div>
        <div class="filter-content-wrapper" ref="content">
            <div class="select-row">
                <div class="search">
                    <input
                        @input="throttledSearch($event)" 
                        @keyup.esc="deactivate()"
                        ref="search" 
                        type="text" 
                        class="input" 
                        :value="query" 
                        placeholder="Search" />
                </div>

                <div class="select software" v-if="aggs.software">
                    <multiselect 
                        v-model="payload.software" 
                        placeholder="Software"
                        open-direction="bottom"
                        track-by="item"
                        label="item"
                        :multiple="true"
                        :searchable="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :options="aggs.software" 
                        :show-labels="false"
                        :taggable="false">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">Software [{{ values.length }}]</span></template>                    
                        <template slot="tag"><span></span></template>
                        <template slot="option" slot-scope="props">
                            <div class="option-wrapper">
                                <span class="enable" v-if="in_list(props.option, payload.software)"><img src="/static/images/yes.png" loading="lazy" alt="Selected" width="20" height="20" /></span>
                                <span class="disable" v-if="!in_list(props.option, payload.software)"></span>
                                <span class="item">{{ props.option.item }}</span>
                                <span class="count">{{ props.option.count }}</span>
                            </div>
                        </template>                    
                    </multiselect>
                    <a class="action" @click="empty('software')" v-if="payload.software.length > 0">Clear All</a>
                </div>
                <span class="placeholder" v-if="!aggs.software"></span>

                <div class="select slot_features" v-if="aggs.slot_features">
                    <multiselect 
                        v-model="payload.slot_features" 
                        placeholder="Features"
                        open-direction="bottom"
                        track-by="item"
                        label="item"
                        :multiple="true"
                        :searchable="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :options="aggs.slot_features" 
                        :show-labels="false"
                        :taggable="false">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">Features [{{ values.length }}]</span></template>                    
                        <template slot="tag"><span></span></template>
                        <template slot="option" slot-scope="props">
                            <div class="option-wrapper">
                                <span class="enable" v-if="in_list(props.option, payload.slot_features)"><img src="/static/images/yes.png" loading="lazy" alt="Selected" width="20" height="20"></span>
                                <span class="disable" v-if="!in_list(props.option, payload.slot_features)"></span>
                                <span class="item">{{ props.option.item }}</span>
                                <span class="count">{{ props.option.count }}</span>
                            </div>
                        </template>                    
                    </multiselect>
                    <a class="action" @click="empty('slot_features')" v-if="payload.slot_features.length > 0">Clear All</a>
                </div>
                <span class="placeholder" v-if="!aggs.slot_features"></span>

                <div class="select volatility" v-if="aggs.volatility">
                    <multiselect 
                        v-model="payload.volatility" 
                        placeholder="Volatility"
                        open-direction="bottom"
                        track-by="item"
                        label="item"
                        :multiple="true"
                        :searchable="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :options="aggs.volatility" 
                        :show-labels="false"
                        :taggable="false">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">Volatility [{{ values.length }}]</span></template>                    
                        <template slot="tag"><span></span></template>
                        <template slot="option" slot-scope="props">
                            <div class="option-wrapper">
                                <span class="enable" v-if="in_list(props.option, payload.volatility)"><img src="/static/images/yes.png" loading="lazy" alt="Selected" width="20" height="20"></span>
                                <span class="disable" v-if="!in_list(props.option, payload.volatility)"></span>
                                <span class="item">{{ props.option.item }}</span>
                                <span class="count">{{ props.option.count }}</span>
                            </div>
                        </template>                    
                    </multiselect>
                    <a class="action" @click="empty('volatility')" v-if="payload.volatility.length > 0">Clear All</a>
                </div>
                <span class="placeholder" v-if="!aggs.volatility"></span>

                <div class="select themes" v-if="aggs.themes">
                    <multiselect 
                        v-model="payload.themes" 
                        placeholder="Themes"
                        open-direction="bottom"
                        track-by="item"
                        label="item"
                        :multiple="true"
                        :searchable="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :options="aggs.themes" 
                        :show-labels="false"
                        :taggable="false">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">Themes [{{ values.length }}]</span></template>                    
                        <template slot="tag"><span></span></template>
                        <template slot="option" slot-scope="props">
                            <div class="option-wrapper">
                                <span class="enable" v-if="in_list(props.option, payload.themes)"><img src="/static/images/yes.png" loading="lazy" alt="Selected" width="20" height="20"></span>
                                <span class="disable" v-if="!in_list(props.option, payload.themes)"></span>
                                <span class="item">{{ props.option.item }}</span>
                                <span class="count">{{ props.option.count }}</span>
                            </div>
                        </template>                    
                    </multiselect>
                    <a class="action" @click="empty('themes')" v-if="payload.themes.length > 0">Clear All</a>
                </div>
                <span class="placeholder" v-if="!aggs.themes"></span>

                <div class="select sorting" v-if="aggs.sorting">
                    <multiselect 
                        v-model="payload.sorting" 
                        placeholder="Sorting"
                        open-direction="bottom"
                        :searchable="false"
                        :options="aggs.sorting"
                        :preselect-first="true"
                        :allow-empty="false"
                        :show-labels="false">
                        <template slot="singleLabel">Sorting</template>                    
                        <template slot="option" slot-scope="props">
                            <div class="option-wrapper">
                                <span class="enable" v-if="payload.sorting == props.option"><img src="/static/images/yes.png" loading="lazy" alt="Selected" width="20" height="20"></span>
                                <span class="disable" v-if="payload.sorting != props.option"></span>
                                <span class="item">{{ props.option }}</span>
                            </div>
                        </template>                    
                    </multiselect>
                </div>
                <span class="placeholder" v-if="!aggs.sorting"></span>

                <div class="select short cpp" v-if="aggs.cpp">
                    <multiselect 
                        v-model="payload.cpp" 
                        placeholder="Count"
                        open-direction="bottom"
                        :searchable="false"
                        :options="aggs.cpp" 
                        :preselect-first="true"
                        :allow-empty="false"
                        :show-labels="false">
                        <template slot="option" slot-scope="props">
                            <div class="option-wrapper">
                                <span class="enable" v-if="payload.cpp == props.option"><img src="/static/images/yes.png" loading="lazy" alt="Selected" width="20" height="20"></span>
                                <span class="disable" v-if="payload.cpp != props.option"></span>
                                <span class="item">{{ props.option }}</span>
                            </div>
                        </template>                    
                    </multiselect>
                </div>
                <span class="placeholder short" v-if="!aggs.cpp"></span>

            </div>
            <div :class="{'selected-row': true, 'is-opened': is_opened}">
                <button class="filter" @click="deactivate()" v-if="query">
                    <span>{{ query }}</span>
                    <span>
                        <img src="/static/images/close.png" loading="lazy" width="13" height="13" alt="Remove" title="Remove" />
                    </span>
                </button>
                <button class="filter" v-for="item in filters" :key="item.c + ':' + item.v.item" @click="remove(item)">
                    <span>{{ item.v.item }}</span>
                    <span>
                        <img src="/static/images/close.png" loading="lazy" width="13" height="13" alt="Remove" title="Remove" />
                    </span>
                </button>
                <button class="clear" @click="clear" v-if="filters.length > 0 || query">
                    Clear All Filters
                </button>
            </div>
            <div class="action-row">
                <button class="clear" @click="clear" v-if="filters.length > 0 || query">
                    Clear All Filters
                </button>
                <span></span>
                <button class="done" @click="close">
                    <span v-if="found !== null">See slots ({{ found }})</span>
                    <span v-if="found === null">See all slots</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Vuex from 'vuex'
import { mapGetters } from 'vuex'
import StoreFilterSlots from './StoreFilterSlots'
import PublicRating from './PublicRating'
import PublicRatingFound from './PublicRatingFound'
import PublicRatingPaging from './PublicRatingLoadMore'

import Multiselect from 'vue-multiselect'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'flatpickr/dist/flatpickr.css'

export default {
	name: 'PublicFilterSlots',
	props: [
        'id',
        'slot_features',
        'sorting',
        'cpp',
        'total',
        'paging_mode',
	],
	components: {
        Multiselect
	},
	data () {
		return {
            query: null,
            payload: {
                query: null,
                software: [],
                volatility: [],
                themes: [],
                slot_features: [],
                sorting: this.sorting,
                cpp: this.cpp,
            },
            page: 1,
            _page: 1,
            is_opened: false
		}
	},
	computed: {
		...mapGetters({
            loading: 'loading',
            aggs: 'aggs',
            data: 'data',
            found: 'found',
        }),
        throttledSearch() {
            return this.throttle(this.search, 500);
        },
        filters() {
            return [
                ...this.payload.software.map(i => { return {c: 'software', v: i}}), 
                ...this.payload.volatility.map(i => { return {c: 'volatility', v: i}}), 
                ...this.payload.themes.map(i => { return {c: 'themes', v: i}}), 
                ...this.payload.slot_features.map(i => { return {c: 'slot_features', v: i}}), 
            ]
        },
        filter_info() {
            return 'Filters' + (this.filters.length > 0 ? ' (' + this.filters.length + ')' : '')
        },
        comp_found() {
            return this.found !== null ? this.found : this.total           
        }
	},
	methods: {
        open() {
            this.$refs.content.style.display = 'flex'
            const body = document.body;
            body.classList.add('no-scroll');
            this.is_opened = true
        },
        close() {
            this.$refs.content.style.display = 'none'
            const body = document.body;
            body.classList.remove('no-scroll');
            this.is_opened = false
        },
        load(is_init=null, is_append=false) {
            if (is_append && this.data === null) {
                this.$store.dispatch('set_append', {data: (document.getElementById('d_' + this.id) || {}).innerHTML})
            }
			this.$store.dispatch('load', {
                payload: this.payload, 
                page: this.page,
                hash: this.id,
                is_init: is_init,
                slot_features: this.slot_features,
                is_append: is_append
            })
            if (!is_init && !is_append) {
                this.$refs.wrapper.scrollIntoView()
            }
        },
        clear() {
            this.payload = {
                query: null,
                software: [],
                slot_features: [],
                volatility: [],
                themes: [],
                sorting: this.payload.sorting,
                cpp: this.payload.cpp,
            }
            this.deactivate()
        },
        empty(item) {
            this.payload[item] = []
        },
        remove(item) {
            const index = this.payload[item.c].indexOf(item.v);
            if (index > -1) {
                this.payload[item.c].splice(index, 1);
            }            
        },
        search() {
            this.payload.query = this.query
        },
        deactivate() {
            this.query = null
            this.search()
        },
        throttle(callback, limit) {
            var wait = false;
            return function (event) {
                this.query = event.target.value
                if (!wait) {
                    wait = true;
                    setTimeout(function () {
                        wait = false;
                        callback.call();
                    }, limit);
                }
            }
        },
        in_list(option, items) {
            return items.map(v => {return v.item}).includes(option.item)
        },
        push() {
            const url = new URL(window.location)
            var res = {} 
            if (this.payload.query) {
                res.query = [encodeURIComponent(this.payload.query)]
            }
            if (this.payload.sorting != this.sorting) {
                res.sorting = [this.payload.sorting]
            }
            if (this.payload.cpp != this.cpp) {
                res.cpp = [this.payload.cpp]
            }
            if (this.page > 1) {
                res.page = [this.page]
            }
            for (const i in this.filters) {
                var v = this.filters[i]
                var a = res[v.c] || []
                a.push(encodeURIComponent(v.v.item))
                res[v.c] = a
            }                
            url.hash = Object.entries(res).map(v => {return v[0] + '=' + v[1].join(':')}).join('$')
            window.history.pushState({}, '', url)

            this.load()
        },
	},
    created() {
        this.$store = new Vuex.Store(StoreFilterSlots)
        this.$store.watch(
            (state) => {
                return state.data;
            },
            (v, pv) => {
                if (pv === null) {
                    new Vue({render: h => h(PublicRating, {props: {'data': this.data, 'category': 'games'}})}).$mount('#d_' + this.id)
                    new Vue({render: h => h(PublicRatingFound, {props: {'found': this.found, 'page': this.page, 'mode': this.paging_mode}})}).$mount('#f_' + this.id)
                }
            }, {deep: true}
        );        
        new Vue({render: h => h(PublicRatingPaging, {
                props: {
                    'found': this.comp_found, 
                    'cpp': this.payload.cpp, 
                    'page': this.page,
                    'cta': 'Load More',
                },
                on: {page: (p) => {
                    this.page = p
                }}
            })}).$mount('#p_' + this.id)
        // get data from #query
        const url = new URL(window.location)
        var hash = url.hash
        if (hash) {
            const parsedHash = hash.substr(1).split('$').map(i => {
                const [k, v] = i.split('=')
                if (['sorting'].includes(k)) {
                    return {key: k, value: decodeURIComponent(v)}
                } else if (k === 'query') {
                    this.query = decodeURIComponent(v)
                    return {key: k, value: this.query}
                } else if (k === 'cpp') {
                    return {key: k, value: parseInt(v)}
                } else if (k === 'page') {
                    this.page = 0
                    this._page = parseInt(v)
                } else {
                    return {key: k, value: v.split(':').map(j => {return {item: decodeURIComponent(j)}})}
                }
            }).filter(function(i){
                return i !== undefined;
            })
            var payload = Object.assign({}, this.payload, ...parsedHash.map(x => ({[x.key]: x.value})))
            this.payload = payload
        } else {
            this.load(true)
        }
    },
    watch: {
        page: {handler() {
            if (this.page > 0) {
                if (this.paging_mode == 'page') {
                    this.push()
                } else if (this.paging_mode == 'load') {
                    this.load(null, true)
                }
            } 
        }},
        payload: {deep: true, handler() {
            if (this.paging_mode == 'load') {
                this.$store.dispatch('set_append', {data: ''})
            }
            if (this.page == 0) {
                this.page = this._page
            } else if (this.page == 1) {
                this.push()
            } else if (this.page > 1) {
                this.page = 1
            }
        }},
    }
}
</script>

<style lang="scss">
.filter-wrapper {
    .multiselect__option--selected {
        background: none;
        color: #000000;
        font-weight: normal;
    }
    .multiselect__option--highlight {
        background: #f5f5f5;
        color: #000000;
        font-weight: normal;
    }
}
</style>
