const mutations = {
    set_data(state, payload) {
      if (payload.ok) {
        state.data = payload.data
        state.found = payload.found
        state.aggs = payload.aggs
      }
    },
    set_append(state, payload) {
      state.data = payload.data || ''
    },
    append_data(state, payload) {
      if (payload.ok) {
        state.data += payload.data
        state.found = payload.found
        state.aggs = payload.aggs
      }
    },
    set_loading(state, payload) {
      state.loading = payload
    },
  }
  
  const actions = {
    async load({commit}, payload) {
      commit('set_loading', true)
      fetch('/api/v3/search/filter/slots', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
      })
      .then(stream => stream.json())
      .then(data => commit(payload.is_append ? 'append_data' : 'set_data', data))
      .catch(error => console.error(error))            
      .finally(() => commit('set_loading', false))            
    },
    async set_append({commit}, payload) {
      commit('set_append', payload)
    },
    async set_loading({commit}, payload) {
      commit('set_loading', payload)
    },
  }
  
  const getters = {
      loading: (state) => state.loading,
      found: (state) => state.found,
      data: (state) => state.data,
      aggs: (state) => state.aggs,
      append: (state) => state.append,
  }
  
  export default {
      state() {
          return {
              loading: false,
              data: null,
              append: null,
              found: null,
              aggs: {},
          }
      },
      getters: getters,
      mutations: mutations,
      actions: actions
  }