import cookie from 'vue-cookies'

const mutations = {
  add(state, payload) {
    state.message = null
    if (!state.items.map(item => item.alias).includes(payload.alias)) {
        if (state.items.length < state.cnt) {
            var doc = {
                title: payload.title,
                alias: payload.alias,
                logo: payload.logo,
            }
            state.items.push(doc)
            cookie.set('comparator', JSON.stringify(state.items))
        }
    }
  },
  clear(state) {
      state.items = []
      cookie.remove('comparator')
  },
  remove(state, payload) {
    state.items.splice(payload, 1)
    cookie.set('comparator', JSON.stringify(state.items))
    state.message = null
  },
  load(state, payload) {
    var _vs = cookie.get('comparator')
    state.items = _vs ? JSON.parse(_vs) : []
    state.cnt = payload.cnt
  }
}

const actions = {
  async comparator_add({commit}, payload) {
    commit('add', payload)
  },
  async comparator_clear({commit}) {
    commit('clear')
  },
  async comparator_remove({commit}, payload) {
    commit('remove', payload)
  },
  async comparator_load({commit}, payload) {
    commit('load', payload)
  },
}

const getters = {
  compare_items: (state) => state.items,
  compare_keys: (state) => state.items.map(item => item.alias),
  compare_count: (state) => state.items.length,
  compare_message: (state) => state.message
}

export default {
    state: {
      items: [],
      message: null,
      cnt: 3
    },
    getters: getters,
    mutations: mutations,
    actions: actions
}