<template>
    <div class="comparator-panel" v-if="count > 0">
        <div class="panel-wrapper">
            <div class="items">
                <div class="item" v-for="(item, i) in items" :key="i">
                    <div class="item-content">
                        <button class="remove" @click="remove(i)">
                            <svg viewBox="0 0 32 32" >
                                <use href="#close" />
                            </svg>                                    
                        </button>
                        <div class="logo-wrapper" v-if="item.logo">
                            <picture>
                                <source :srcset="[item.logo + '.webp']" type="image/webp">
                                <img :src="item.logo" loading="lazy" :alt="item.title" :title="item.title" />
                            </picture> 
                        </div>
                        <div class="title-wrapper">{{ item.title }}</div>
                    </div>
                </div>
            </div>
            <div class="actions">
                <a class="compare" :href="url">{{ terms.compare }}</a>
                <button class="clear" @click="clear">{{ terms.clear }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'PublicComparatorPanel',
	props: [
        'url',
        'cnt',
        'terms'
	],
	computed: {
		...mapGetters({
			items: 'compare_items',
            count: 'compare_count',
            message: 'compare_message'
        }),
	},
	methods: {
        clear() {
			this.$store.dispatch('comparator_clear')
        },
        remove(index) {
			this.$store.dispatch('comparator_remove', index)
        }
    },
    mounted() {
        this.$store.dispatch('comparator_load', {cnt: this.cnt})
    }
}
</script>

