<template>
    <div class="fields-wrapper">
        <div class="field">
            <label>Your Rate</label>
            <div class="rate-wrapper">
                <div class="rate-stars">
                    <svg :class="item.class" viewBox="0 0 24 24" @click="vote(item.value)" v-for="item in stars" :key="item.value">
                        <use href="#star" />
                    </svg>                
                </div>                    
                <div :class="['rate-string', 'rate-' + data.rate]" v-if="data.rate">
                    {{ string_rate[data.rate-1] }}
                </div>
            </div>
            <span class="error" v-if="errors.rate">{{ errors.rate }}</span>
        </div>
    </div>
</template>

<script>
export default {
	name: 'PublicFormVote',
    props: [
        'data',
        'meta',
        'errors',
        'message',
        'loading',
    ],
    data() {
        return {
            stars: [],
            string_rate: ['Weak', 'Normal', 'Good', 'Perfect', 'Excellent']
        }
    },    
	methods: {
        refresh() {
            this.stars = [1, 2, 3, 4, 5].map(x => {
                return {class: (this.data.rate !== undefined && this.data.rate >= x) ? 'rate-' + this.data.rate : 'inactive', value: x}
            })
        },
        vote(i) {
            this.data.rate = i
            this.refresh()
        }
    },
    created() {
        this.refresh()
    }
}
</script>