<template>
    <div :class="category+'-wrapper'" v-append="data"></div>
</template>

<script>
export default {
	name: 'PublicRating',
    props: [
        'loading',
        'data',
        'category',
    ],
}
</script>