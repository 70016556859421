<template>
    <button @click="compare" class="comparator-button" :class="{selected: is_compare(alias)}">
        <span v-if="is_compare(alias)">{{ terms.exist }}</span>
        <span v-if="!is_compare(alias)">{{ terms.add }}</span>
    </button>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'PublicComparatorButton',
	props: [
        'alias',
        'title',
        'logo',
        'terms',
	],
	computed: {
		...mapGetters({
            compare_keys: 'compare_keys'
        }),
        payload() {
            return {alias: this.alias, title: this.title, logo: this.logo}
        }
	},
	methods: {
        compare() {
			this.$store.dispatch('comparator_add', this.payload)
        },
        is_compare(item) {
            return this.compare_keys.includes(item)
        }
	},
}
</script>

