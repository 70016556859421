<template>
    <div class="load-more-wrapper">
        <div class="stats">
            <div class="count">{{ count }} <span>/ {{ found }}</span></div>
            <div class="bar-wrapper">
                <span class="bar" :style="bar_style"></span>
            </div>
            <div class="count"><span>{{ percent }}%</span></div>
        </div>
        <button :class="{load: true, disabled: is_full}" @click="load" v-html="cta" :disabled="is_full"></button>
    </div>
</template>

<script>
export default {
	name: 'PublicRatingLoadMore',
    props: [
        'cpp',
        'found',
        'page',
        'cta',
    ],
	computed: {
        count() {
            var c = this.page * this.cpp
            return c < this.found ? c : this.found
        },
        bar_style() {
            return 'width: ' + this.percent + '%'
        },
        percent() {
            return parseInt((this.count / this.found) * 100)
        },
        is_full() {
            return this.count == this.found
        }
	},
	methods: {
        load() {
            this.page += 1
            this.$emit('page', this.page)
        },
    }
}
</script>
