<template>
    <div class="filter-found">
        <div>{{ found }} {{ items }} found</div>
        <div class="page" v-if="found > 0 && mode == 'page'">Page {{ page }}</div>
    </div>
</template>

<script>
export default {
	name: 'PublicRatingFound',
    props: [
        'loading',
        'found',
        'page',
        'mode',
    ],
	computed: {
        items() {
            return this.found == 1 ? 'Item' : 'Items';
        },
	},
}
</script>
