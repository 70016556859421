<template>
    <div class="fields-wrapper">
        <div class="field">
            <label>Subject</label>
            <input v-model="data.subject" :disabled="loading" />
            <span class="error" v-if="errors.subject">{{ errors.subject }}</span>
        </div>
        <div class="field">
            <label>Contacts: skype, e-mail</label>
            <input v-model="data.contacts" :disabled="loading" />
            <span class="error" v-if="errors.contacts">{{ errors.contacts }}</span>
        </div>
        <div class="field">
            <label>Your Name</label>
            <input v-model="data.name" :disabled="loading" />
            <span class="error" v-if="errors.name">{{ errors.name }}</span>
        </div>
        <div class="field">
            <label>Message</label>
            <textarea v-model="data.message" :disabled="loading"></textarea>
            <span class="error" v-if="errors.message">{{ errors.message }}</span>
        </div>
    </div>
</template>

<script>
export default {
	name: 'PublicFormTicket',
    props: [
        'data',
        'meta',
        'errors',
        'message',
        'loading',
    ],
}
</script>