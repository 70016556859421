<template>
    <div class="search-wrapper" :class="{closed: closed}" tabindex="0"> 
        <button v-if="ctrl && closed" v-html="ctrl" @click="toggle" class="menu-mobile"></button>
        <div class="search-background" @click="toggle" v-if="!(ctrl && closed)"></div>
        <div class="search-active" v-if="!(ctrl && closed)">
            <input 
                @input="throttledSearch($event)" 
                @keyup.esc="deactivate()"
                ref="search" 
                type="text" 
                class="input" 
                :value="query" 
                placeholder="Search" 
                @click="closed=false"
            />
            <div class="result-wrapper" v-if="items.length && !closed">
                <div class="result-item" v-for="(item, j) in items" :key="j">
                    <a :href="item.url" :class="{disabled: !item.is_active}">
                        {{ item.title }}
                        <span v-for="tag in item.tags" :key="tag.title" :class="tag.class">{{ tag.title }}</span>
                    </a>
                </div>
            </div>
            <div class="result-wrapper no-result" v-if="!items.length && !closed">
                <span>No results found</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name: 'PublicSearch',
	props: [
        'focus',
        'ctrl'
	],
	data () {
		return {
            query: null,
            items: [],
            loading: false,
            closed: false,
		}
    },
	computed: {
        throttledSearch() {
            return this.throttle(this.search, 500);
        }, 
	},
	methods: {
        search() {
            fetch('/api/v3/search/page', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({query: this.query})
            })
            .then(stream => stream.json())
            .then(data => this.items = data.items)
            .catch(error => console.error(error))            
        },
        clear() {
            if (this.focus) {
                this.$nextTick(() => this.$refs.search.focus())
            }
            this.deactivate()
        },
        deactivate() {
            this.query = null
            this.items = []
        },
        throttle(callback, limit) {
            var wait = false;
            return function (event) {
                this.query = event.target.value
                if (!wait) {
                    wait = true;
                    setTimeout(function () {
                        wait = false;
                        callback.call();
                    }, limit);
                }
            }
        },
        toggle() {
            this.deactivate()
            this.closed = !this.closed
            if (!this.closed) {
                this.$nextTick(() => this.$refs.search.focus())
            }
        },
        open() {
            this.deactivate()
            this.closed = false
        }
	},
    mounted() {
        this.clear()
    }
}
</script>

<style scoped lang="scss">
</style>
