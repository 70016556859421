<template>
    <div class="fields-wrapper">
        <div class="field">
            <input v-model="data.email" :disabled="loading" placeholder="Enter your e-mail"/>
            <span class="error" v-if="errors.email">{{ errors.email }}</span>
        </div>
        <div class="field check">
            <div class="check-wrapper">
                <input id="is_agree" type="checkbox" v-model="data.is_agree" />
                <label for="is_agree">Yes, I agree to receiving emails from Gamblescope.com on gambling news and offers</label>
            </div>
            <span class="error" v-if="errors.is_agree">{{ errors.is_agree }}</span>
        </div>
    </div>
</template>

<script>
export default {
	name: 'PublicFormSubscribe',
    props: [
        'data',
        'meta',
        'errors',
        'message',
        'loading',
    ],
}
</script>