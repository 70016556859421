<template>
    <div class="activity-wrapper">
        <div class="form-wrapper" v-if="!message">
            <component :is="form" :data="data" :errors="errors" :message="message" :loading="loading" :meta="meta" @update="update" @loading="set_loading"></component>
            <div class="action-wrapper" v-if="!message">
                <button @click="send" :disabled="loading" class="send">{{ meta.action }}</button>
            </div>
        </div>
        <div class="result-wrapper" v-if="message">
            <p>{{ message }}</p>
            <button class="retry" v-if="meta.retry || false" @click="retry">Retry</button>
        </div>
    </div>
</template>

<script>
import PublicFormTicket from './PublicFormTicket.vue'
import PublicFormVote from './PublicFormVote.vue'
import PublicFormComplaint from './PublicFormComplaint.vue'
import PublicFormSubscribe from './PublicFormSubscribe.vue'

export default {
	name: 'PublicForm',
	components: {
        PublicFormTicket,
        PublicFormVote,
        PublicFormComplaint,
        PublicFormSubscribe,
	},
    props: [
        'meta',
    ],
    data() {
        return {
            data: {},
            errors: {},
            message: null,
            loading: false
        }
    },
	computed: {
        form() {
            return 'PublicForm' + this.meta.core;
        },
    },
	methods: {
        send() {
            this.loading = true
            fetch('/api/v3/activity', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Object.assign({}, this.data, this.meta))
            })
            .then(stream => stream.json())
            .then(data => {
                this.data = data.data
                this.errors = data.errors
                this.message = data.message
            })
            .catch(error => console.error(error))            
            .finally(() => this.loading = false)            
        },
        retry() {
            this.message = null
            this.data = {}
            this.errors = {}
        },
        update(data) {
            console.log('update:', data)
            this.data = data
        },
        set_loading(v) {
            this.loading = v
        }
    }
}
</script>