<template>
    <iframe :src="url" v-if="url !== undefined"></iframe>
</template>

<script>
export default {
	name: 'PublicSlotPlay',
    props: [
        'alias',
    ],
    data() {
        return {
            url: undefined,
            loading: false
        }
    },
	methods: {
        load() {
            this.loading = true
            fetch('/api/v3/play', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Object.assign({}, {alias: this.alias}))
            })
            .then(stream => stream.json())
            .then(data => {
                if (data.ok) {
                    this.url = data.url
                }
            })
            .catch(error => console.error(error))            
            .finally(() => this.loading = false)            
        },
    },
    created() {
        this.load()
    }
}
</script>