<template>
    <div class="geo-selector-wrapper">
        <button class="open" @click="open">{{ label_string }}</button>
        <div class="is-blurred" v-if="is_open" @click="close"></div>
        <div class="window-wrapper" v-if="is_open">
            <button class="close" @click="close">
                <img src="/static/images/close.png" loading="lazy" width="24" height="24" alt="Close" title="Close" />
            </button>
            <h3>Regional Settings</h3>
            <p class="geo-info">Choose your Country:</p>
            <!-- @search-change="asyncFind" :internal-search="false" -->
            <div class="select">
                <multiselect 
                    v-model="current_country" 
                    placeholder="Select country"
                    open-direction="bottom"
                    track-by="country"
                    label="country"
                    :searchable="true"
                    :options="countries" 
                    :loading="isLoading"
                    :hide-selected="false"
                    :show-no-results="true"
                    :maxHeight=260
                    :allow-empty="false"
                    :show-labels="false">
                    <template slot="noOptions">Type to search</template>                    
                    <template slot="noResult">Country not found</template>                    
                    <template slot="option" slot-scope="props">
                        <div class="option-wrapper">
                            <span class="flag"><img :src="'/static/images/geo/' + props.option.iso + '.png'" :alt="props.option.country" :title="props.option.country" /></span>
                            <span class="name">{{ props.option.country }}</span>
                        </div>
                    </template>                    
                    <template slot="singleLabel" slot-scope="props">
                        <div class="option-wrapper">
                            <span class="flag"><img :src="'/static/images/geo/' + props.option.iso + '.png'" :alt="props.option.country" :title="props.option.country" /></span>
                            <span class="name">{{ props.option.country }}</span>
                        </div>
                    </template>                    
                </multiselect>
            </div>
            <p class="geo-info">Popular Countries:</p>
            <div class="countries">
                <a @click="select(item)" :class="{default: item.iso == location_iso}" v-for="item in popular" :key="item.iso">
                    <span class="flag"><img :src="'/static/images/geo/' + item.iso + '.png'" :alt="item.country" :title="item.country" /></span>
                    <span class="name">{{ item.country }}</span>
                </a>
            </div>
            <div class="action">
                <button @click="save">Save</button>
            </div>            
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'flatpickr/dist/flatpickr.css'

export default {
	name: 'PublicGeo',
    props: [
        'country',
        'iso',
        'location',
        'location_iso',
        'label',
        'closed'
    ],
	components: {
        Multiselect
	},
	computed: {
        label_string() {
            return this.label || this.current_country.country;
        },
	},
	data () {
		return {
            is_open: false,
            popular: [
                {iso: 'gb', country: 'United Kingdom'},
                {iso: 'ca', country: 'Canada'},
                {iso: 'au', country: 'Australia'},
                {iso: 'nz', country: 'New Zealand'},
                {iso: 'no', country: 'Norway'},
                {iso: 'de', country: 'Germany'},
            ],
            current_country: null,
            current_location: null,
            countries: [],
            isLoading: false,
		}
	},
	methods: {
        open() {
            const body = document.body;
            body.classList.add('no-scroll');
            this.is_open = true
        },
        close() {
            const body = document.body;
            body.classList.remove('no-scroll');
            this.is_open = false
        },
        select(item) {
            this.current_country = item
        },
        save() {
            location.reload()
        },
        asyncFind (query) {
            this.isLoading = true
            fetch('/api/v3/geo', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({query: query})
            })
            .then(stream => stream.json())
            .then(data => this.countries = data.countries)
            .catch(error => console.error(error))            
            .finally(() => this.isLoading = false)            
        },        
    },
    created() {
        this.isLoading = true
        fetch('/api/v3/geo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        })
        .then(stream => stream.json())
        .then(data => this.countries = data.countries)
        .catch(error => console.error(error))            
        .finally(() => this.isLoading = false)            

        this.current_country = {iso: this.iso, country: this.country}
        this.current_location = {iso: this.location_iso, country: this.location}
        if(!this.popular.map(i => { return i.iso }).includes(this.location_iso)) {
            this.popular = [this.current_location].concat(this.popular);
        }
        this.popular = this.popular.filter(item => item.iso == this.location_iso).concat(this.popular.filter(item => item.iso != this.location_iso))
        if (this.closed !== true) {
            this.open()
        }
    },
    watch: {
        current_country: {
            handler() {
                this.$cookies.set("country", this.current_country.country);
                this.$cookies.set("iso", this.current_country.iso);
            }, deep: true
        }
    }
}
</script>


<style lang="scss">
.geo-selector-wrapper {
    .multiselect__option--selected {
        background: none;
        color: #000000;
        font-weight: normal;
    }
    .multiselect__option--highlight {
        background: #f5f5f5;
        color: #000000;
        font-weight: normal;
    }
}
</style>